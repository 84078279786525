.modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* other styles */
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .narrow-button {
    width: 340px;
  }
  

  .modal-close {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    /* margin: 8px 0; */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .modal-close:hover {
    background-color: #0056b3;
  }
  