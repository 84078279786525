/* .rbc-calendar {
    height: 500px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
  } */

  .rbc-calendar {
    height: 500px;
    width: 100%; /* Adjust the width to 100% */
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }
  
  .rbc-header {
    font-weight: bold;
    text-align: center;
  }
  
  .rbc-header + .rbc-header {
    border-left: 1px solid #ddd;
  }
  
  .rbc-header > .rbc-row > * + * {
    border-left: 1px solid #ddd;
  }
  
  .rbc-event {
    border: none;
    box-shadow: none;
    background-color: #1f2d3d;
    color: #fff;
    font-size: 12px;
    padding: 3px;
    border-radius: 0;
  }
  
  .rbc-today {
    background-color: #f0f0f0;
  }
  
  .rbc-agenda-time-cell {
    text-align: right;
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
  
  .rbc-agenda-event-cell {
    padding: 5px;
  }
  
  .rbc-agenda-date-cell,
  .rbc-agenda-time-cell {
    border: none;
    background-color: #f0f0f0;
    padding: 5px;
    font-size: 14px;
    color: #333;
  }
  
  .rbc-agenda-view {
    display: flex;
    flex-direction: column;
  }
  
  .rbc-agenda-time-cell + .rbc-agenda-time-cell {
    border-top: 1px solid #ddd;
  }

  .rbc-calendar {
    height: 500px; /* adjust height */
    width: 200%; /* adjust width */
  }

  .rbc-calendar {
    height: 500px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
  }
  
  .rbc-header {
    font-weight: bold;
    text-align: center;
  }
  
  .rbc-header + .rbc-header {
    border-left: 1px solid #ddd;
  }
  
  .rbc-header > .rbc-row > * + * {
    border-left: 1px solid #ddd;
  }
  
  .rbc-event {
    border: none;
    box-shadow: none;
    background-color: #1f2d3d;
    color: #fff;
    font-size: 12px;
    padding: 3px;
    border-radius: 0;
  }
  
  .rbc-today {
    background-color: #f0f0f0;
  }
  
  .rbc-agenda-time-cell {
    text-align: right;
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
  
  .rbc-agenda-event-cell {
    padding: 5px;
  }
  
  .rbc-agenda-date-cell,
  .rbc-agenda-time-cell {
    border: none;
    background-color: #f0f0f0;
    padding: 5px;
    font-size: 14px;
    color: #333;
  }
  
  .rbc-agenda-view {
    display: flex;
    flex-direction: column;
  }
  
  .rbc-agenda-time-cell + .rbc-agenda-time-cell {
    border-top: 1px solid #ddd;
  }
  
  .rbc-calendar {
    height: 500px; /* adjust height */
    width: 200%; /* adjust width */
  }
  
  .calendar-container {
    min-height: 500px;
  }
  
  .calendar-wrapper {
    width: 100%;
    overflow: show;
  }
  
  .club-detail {
    width: auto;
    max-width: 400px;
    min-width: 400px;
    margin: 0 auto;
  }
  
  .rbc-header > .rbc-row > *:not(.rbc-date-cell) {
    pointer-events: none;
  }
  