.small {
    width: 100px;
    height: 30px;
    font-size: 14px;
    text-align: center;
    align-items: center;
    line-height: 1.5; /* increase the line-height to provide more space between the lines */
    padding: 5px; /* add some padding to the button */
  }
  

  li {
    display: flex;
  }
  
  li button {
    margin-left: 10px; /* add some space between the user name and the button */
  }
  
  .movie-title {
    font-size: 12px;
    color: #2a2a2a;
    margin-bottom: 2px;
  }
  

  .upcoming-movies {
    display: flex;
    justify-content: space-around;
  }
  
  .upcoming-movie {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
  }
  