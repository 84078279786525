.home-wrapper {
    max-width: 890px;
    margin: 0 auto;
    padding: 10px 10px 40px;

  }
  
  .home-wrapper h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .home-wrapper p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .home-button {
    background-color: #4285f4;
    color: #fff;
    font-size: 1.2rem;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .home-button:hover {
    background-color: #3367d6;
  }




    .home-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 70px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    max-height: 94vh;
    overflow-y: auto;
    max-width: auto;
  }

  .signUp {
    color: blue;
  }
  