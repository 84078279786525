.login-form-container {
    margin-left: 1rem;
  }
  
  .club-detail {
    margin-left: 1rem;
  }
  
  .login-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
  
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    margin-top: 70px;
    margin-left: 30px;
  
    max-height: 94vh;
    overflow-y: auto;
  
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 310px;
    padding: 10px;
    z-index: 2;
  }
  
  .login-form {
    width: 300px;
    display: flex;
    flex-direction: column;
  }
  
  input[type="text"], input[type="password"] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
  }
  
  input[type="text"], input[type="email"] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
  }

  input[type="submit"] {
    width: 100%;
    background-color: #007bff;
    color: rgb(203, 203, 203);
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  input[type="submit"]:hover {
    background-color: #0056b3;
  }



  /* Add Movie button styling */
.add-movie-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .add-movie-btn:hover {
    background-color: #0056b3;
  }
  



  
  
  /* Movie select */
  .react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    position: absolute;
    top: 38px;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    display: block;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  
  .react-autosuggest__section-container {
    border-bottom: 1px solid #ddd;
  }
  
  .react-autosuggest__section-container--first {
    border-top: 1px solid #ddd;
  }
  
  .react-autosuggest__section-title {
    padding: 10px;
    font-weight: bold;
  }
  








  .other-content-wrapper {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;

    margin-left: 380px;
    width: 900px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    position: fixed;
    max-height: 94%;
    overflow-y: auto;
  }

  .padding {
    padding: 20px;
  }

  .movie-posters-container {
    width: 200px;
    height: 300px;
    overflow: hidden;
  }

  .movie-posters-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .upcoming-movies2 {
    margin-left: 100px;
  }

  .upcoming-movie {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
  





  /* movie form */

  /* Movie form styling */
form label {
    display: block;
    margin: 8px 0;
    font-size: 14px;
  }
  
  form input[type="date"], form input[type="time"] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
  }
  
  form button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    margin: 8px 0;
    margin-right: 8px; /* Add right margin */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  form button:hover {
    background-color: #0056b3;
  }
  
  form button[type="button"] {
    background-color: #6c757d;
  }
  
  form button[type="button"]:hover {
    background-color: #565e64;
  }
  
  form div {
    margin-bottom: 8px;
  }
  




  





  .club-detail h3 {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
    padding-bottom: 8px;
  }
  
  .club-detail ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .club-detail ul li {
    padding: 10px;
    transition: background-color 0.3s;
  }
  
  
  .club-detail ul li a {
    text-decoration: none;
    color: #007bff;
    font-weight: 500;
    transition: color 0.3s;
  }
  .club-detail ul li a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  




  /* Club members and Add members */
.club-members, .add-members {
    margin-bottom: 16px;
  }
  
  h2 {
    font-size: 18px;
    margin-bottom: 12px;
  }
  
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .user-list-item {
    margin: 8px 0;
    font-size: 14px;
  }
  
  .search-input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 14px;
  }
  
  .small-btn {
    padding: 5px 10px;
    margin-left: 8px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s;
  }
  
  .remove-btn {
    background-color: #dc3545;
    color: white;
  }
  
  .remove-btn:hover {
    background-color: #c82333;
  }
  
  .add-btn {
    background-color: #28a745;
    color: white;
  }
  
  .add-btn:hover {
    background-color: #218838;
  }
  
  .edit-users-btn {
    background-color: #6c757d;
    color: white;
    padding: 10px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .edit-users-btn:hover {
    background-color: #565e64;
  }
  
  .delete-club-btn {
    background-color: #ff0000;
    color: white;
    padding: 10px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    margin-left: 5px;
  }

  .delete-club-btn:hover {
    background-color: #900000;

  }


  @media screen and (max-width: 768px) {
    .login-form-wrapper {
      position: static;
      max-width: none;
      margin-bottom: 20px;
    }
  
    .other-content-wrapper {
      position: static;
      margin-left: 0;
      width: 100%;
    }
  }
  