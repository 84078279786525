.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 70px;
    background-color: rgb(220, 220, 220);
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    max-height: 94vh;
    overflow-y: auto;
    max-width: 300px;
    min-width: 300px;
}

text {
    color: white;
}

.red-link {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    margin-left: -5px;
  }
  
  .red-link:hover {
    text-decoration: underline;
  }