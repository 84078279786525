.forms-container {
  display: flex;
  gap: 20px; /* Optional: add some space between the forms */
}
.nav-action.active {
  text-decoration: underline;
}



header {
  padding-top: 5px;
}




/* header */
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #333;
  color: #fff;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}


.nav-action {
  text-decoration: none;
  color: #fff;
  margin-right: 10px;
  padding: 5px;
  border-radius: 5px;
}

.nav-action.active {
  background-color: #fff;
  color: #333;
}
